.carousel {
    margin: 0 auto;
}

.contact-buttons {
    width: 100%;
    margin: 0 auto;
}

.contact-button {
    width: 100%;
    margin: 0 auto;
}

.status-badge {
    text-align: center;
    padding: 10px 20px 10px 20px;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    max-height: 50px;
    line-height: 20px;
}

.status-badge-active {
    background-color: #27a743;
}

.status-badge-inactive {
    background-color: #fd7e14;
}
@media only screen and (max-width: 370px) {
    .listing-action {
        display: none;
    }
}
