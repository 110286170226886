.App {
  text-align: left;
  position: relative;
  min-height: 100vh;
  background-color: #343A40;
  color: #FAFAFA;
}

a, button {
  color: #1983BB !important;
}

#content-wrap {
  padding-bottom: 3rem;    /* Footer height */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clickable {
  color: #1983BB;
}

.clickable:hover {
  cursor: pointer;
  text-decoration: underline;
}

.nowrap {
  white-space: nowrap;
}

.container {
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 20px !important;
}

.btn-primary, .btn-outline-primary {
  border-color: #1983BB !important;
}

.btn-outline-primary:hover {
  background-color: rgba(25, 131, 187, .3) !important;
}

.btn-outline-secondary {
  color: #FAFAFA !important;
}

.btn-outline-secondary:hover {
  background-color: rgba(52, 58, 64, .3) !important;
}


@media only screen and (max-width: 320px) {
  body {
    font-size: 14px !important;
  }
}

#loader{
  position: fixed;
  font-size: 78px;
  z-index: 1000;
  color: #1983BB !important;
  top: 40%;
  left: calc(50% - 36px);
}

.show-loader{
  display: initial;
}

.hide-loader{
  display: none;
}
